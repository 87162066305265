<template>
  <div class="component card" id="location" ref="location">
    <div class="title">
      <h1 v-if="displayLocationWarning" class="error-style">You chose a bottle in {{ this.region.name }}, but an alert area in {{ this.reverseGeocodeRegion.text }}</h1>
      <div v-else>
        <h1>Where should we look?</h1>
        <p>We'll look for online and in-store stock in <em>{{ this.region.name }}</em>.</p>
      </div>

    </div>

    <div class="card-content" v-if="gettingLocation">
        <div class="getting-location">
          <div class="loader"></div>
        </div>
    </div>
    <div class="card-content" style="height: unset" v-else-if="displayLocationWarning">
      <div class="location-warning">
        <p>With Bottlebeagle, each alert is for a specific region. So when your selected location doesn't match the region the bottle is in, it means you would only get alerts for online stock.</p>
        <p>You can select a different location for us to look, continue anyway (and only receive online stock alerts), or begin your search again in the region you'd like.</p>
      </div>
    </div>

    <div class="card-content" v-else>
      <div class="map-meta-container" v-if="location" ref="map">
        <base-map v-if="location" v-bind:coordinates="location.coords" v-bind:radius="radius" @map-loaded="mapWasLoaded"></base-map>
        <div v-else-if="gettingLocation" class="loader">
        </div>
      </div>
      <div v-else class="location-chooser">
        <address-selection v-if="enterAddress" @addressSelected="selectedAddress"></address-selection>
        <div v-else class="options">
          <div class="big-option" @click="locateMe">
            <img src="https://img.icons8.com/ios/50/000000/gps-device.png" srcset="https://img.icons8.com/ios/100/000000/gps-device.png 2x, https://img.icons8.com/ios/150/000000/gps-device.png 3x"/>
            <p>Get my location</p>
          </div>
          <div class="big-option" @click="toggleEnterAddress">
            <img src="https://img.icons8.com/ios/50/000000/worldwide-location.png" srcset="https://img.icons8.com/ios/100/000000/worldwide-location.png 2x, https://img.icons8.com/ios/150/000000/worldwide-location.png 3x"/>
            <p>Enter an address</p>
          </div>
        </div>
      </div>

    </div>
    <div class="radius" v-if="location && !displayLocationWarning">
      <input type="range" min="1" max="100" v-model="internalRadius" class="slider">
    </div>

    <div v-if="location && !displayLocationWarning" class="card-footer">
      <a @click="location = null; displayLocationWarning=false;">
        <div class="footer-back">
          <p>Select another address</p>          
        </div>
      </a>
      <button class="big-button" @click="submitLocation">{{ confirmButtonString }}</button>
      <div class="footer-right"></div>

      </div>
    <div v-else-if="displayLocationWarning" class="card-footer" style="justify-content: center; gap: 14px;">
      <a @click="location = null; displayLocationWarning=false;">
        <div class="footer-back">
          <p>Select another address</p>          
        </div>
      </a>
      <button class="big-button" @click="confirmBadLocation">Continue anyway</button>
      <button class="big-button" @click="resetSearchAndRegion">New search</button>
      <div class="footer-right"></div>
    </div>
    <div v-else class="card-footer">
      <p></p>
    </div>
    <!-- <confirm-modal 
      v-if="displayConfirmModal" 
      @destructive="confirmBadLocation" 
      @gentle="hideCancelModal" 
      v-bind:title="'Your location looks wrong!'" 
      v-bind:subtitle="badLocationSubtitle"
      v-bind:body="'You should start your search in the region you plan to get an alert in, or else your alert might not work as expected.'" 
      v-bind:destructiveButton="'Continue with this location'"
      v-bind:gentleButton="'Try a different address'">
      v-bind:shouldSpin="true"
    </confirm-modal> -->
  </div>
</template>

<script>
const AddressSelection = () => import('./AddressSelection.vue');

const mbxClient = require('@mapbox/mapbox-sdk');
const mbxGeo = require('@mapbox/mapbox-sdk/services/geocoding');

const baseClient = mbxClient({ accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN });
const geoservice = mbxGeo(baseClient);

const BaseMap = () => import('./BaseMap.vue')

export default {
  name: 'Location',
  props: ['region'],
  components: {BaseMap, AddressSelection},
  data() {
    return {
      enterAddress: false,
      address: null,
      location: null,
      gettingLocation: false,
      errorStr: null,
      internalRadius: "1",
      reverseGeocodeRegion: null,
      displayLocationWarning: false,
    };
  },
  computed: {
    confirmButtonString() {
      if (parseInt(this.radius)) {
        let res = this.radius / 1000;
        return "Watch within " + Math.ceil(res) + "km of you";
      } else {
        return "Watch anywhere in " + this.region.name;
      }
    },
    radius() {
        // position will be between 0 and 100
      var minp = 1;
      var maxp = 100;

      // The result should be between 100 an 10000000
      var minv = Math.log(1000);
      var maxv = Math.log(500000);

      // calculate adjustment factor
      var scale = (maxv-minv) / (maxp-minp);

      if (this.internalRadius == 100) {
        return 0;
      }
      return Math.exp(minv + scale*(this.internalRadius-minp));
    },
    badLocationSubtitle: function () {
      return "You've want alerts about a bottle in " + this.region.name + ", but your location is in " + this.reverseGeocodeRegion.text + ".";
    },
  },
  mounted() {
    if (!this.$route.query.itemId || !this.$route.query.retailer) {
      this.scrollToElement();
    }

  },
  methods: {
    mapWasLoaded () {
      this.$refs.map.scrollIntoView({ block: 'center', scrollBehavior: 'smooth' });
    },
    selectedAddress (coordinates) {
      this.location = coordinates;

      this.matchesRegion(this.location.coords, this.region)
        .then(res => {
          if (res === false) {
            this.displayLocationWarning = true;
          }
        })
        .catch(err => {
          console.log("Error checking location matches from address input:" + err);
          this.errorStr = err.message;
          this.gettingLocation = false;
        });
    },
    async locateMe() {
      // gets geolocation and sets it
      this.gettingLocation = true;

      this.getLocation()
        .then(pos => { // get the location
          console.log("received pos:" + pos);
          this.location = pos;
          this.gettingLocation = false;
          return this.matchesRegion(this.location.coords, this.region);
        })
        .then(res => { // handle returned values of checkLocationMatches
          if (res === false) {
            console.log("bad match:" + res);
            this.displayLocationWarning = true;
          } else {
            console.log("good match:" + res);
          }
        }).catch(err => {
          console.log("Error getting location:" + err);
          this.errorStr = err.message;
          this.gettingLocation = false;
        })
    },
    async getLocation() {
      // actually does the geolocating
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error('Geolocation is not available.'));
        }

        navigator.geolocation.getCurrentPosition(pos => {
          resolve(pos);
        }, err => {
          reject(err);
        });
      });
    },
    toggleEnterAddress() {
      this.enterAddress = !this.enterAddress;
    },
    submitLocation() {
      this.$emit('located', this.location.coords, this.radius);
    },
    async matchesRegion(coords, region) {
      return new Promise((resolve, reject) => {
        geoservice.reverseGeocode({
          query: [coords.longitude, coords.latitude],
          types: ["region"],
          limit: 1,
        }).send()
        .then(function (response) {
          let feature = response.body.features[0];
          let shortCode = feature.properties.short_code.split('-').pop();
          let regionName = feature.text;
          let countryShortCode = feature.context[0].short_code;

          this.reverseGeocodeRegion = feature;

          if (region.region.toLowerCase() === shortCode.toLowerCase()) {
            console.log("its a match between region and reverse geocode:" + region.region + " " + shortCode);
            resolve(true);
          } else {
            resolve(false);
          }
        }.bind(this)).catch(error => {
          // uhhh
          console.log("Error reverse geocoding:" + error);
          reject(error);
        });
      });
    },
    confirmBadLocation() {
      this.displayConfirmModal = false;
      this.$emit('located', this.location.coords, '1'); // tiny radius so they don't get alerts
    },
    resetSearchAndRegion() {
      this.$emit('resetCheckoutAndRegion');
    },
    hideCancelModal() {
      this.location = null;
      this.enterAddress = true;
      this.displayConfirmModal = false;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

a {
  cursor: pointer;
}

.card {
  .title {

  }
  .card-content {
    padding: 0;
    height: 274px;
    background-image: url("../assets/blurrymap.png");
    // backdrop-filter: blur(10px);
    // blur the background
    // filter: blur(5px);

    .map-meta-container{
      width: 100%;
      height: 100%;
    }

    .getting-location {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      h1 {
        margin: 0;
      }

      .loader::before {
        content :"Locating...";
      }
    }

    .location-warning {
      // background: #401D18;
      padding: 1em;
      margin: auto auto;
      max-width: 40em;
      display: inline-block;
      color: white;
      h1 {
        margin: auto;
        color: #401D18;
        // max-width: 20em;
        text-decoration: underline;
        text-decoration-color: $emphasis;
      }
    }


    .location-chooser {
      height: 100%;
      width: calc(100% - 20px);
      margin: 0 10px;

      .options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        // padding: 15px 0;
        width: 100%;
        height: 100%;

        .big-option {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          width: 250px;
          height: 80px;
          padding: 1em;
          border-radius: 5px;
          background: none;
          border: 2px solid white;
          box-shadow: 0px 0px 5px 0px $emphasis;

          cursor: pointer;

          img {
            filter: invert(38%) sepia(89%) saturate(714%) hue-rotate(337deg) brightness(114%) contrast(101%);
          }

          p {
            color: white;
            text-align: center;
            font-family: Assistant;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0;
          }

          &:hover {
            transition:all 0.3s ease;
            border: 2px solid $emphasis;
            background: #863B33;

            img {
              filter: none;
            }

            p {
              color: white;
            }
          }
        }
      }

      #address-selection {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
      }

    }
  }
  .radius {
    width: 300px;
    margin: 1em auto;

    .slider {
      -webkit-appearance: none;
      width: 100%;
      height: 8px;
      border-radius: 5px;
      background: #401D18;
      outline: none;
    }
    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $emphasis;
      cursor: pointer;
    }

    .slider::-moz-range-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $emphasis;
      cursor: pointer;
    }

  }

  .card-footer {
    border-top: none;
  }
}



.big-button {
  width: 200px;
  cursor: pointer;
  img {
    position: relative;
    top: 3px;
    margin: 0 0.5em 0 0;
    width: 24px;
    height: 24px;
    filter: brightness(0) invert(1);
    outline: none;
  }
}
.radius {
  width: 40%;
  margin: 0 auto;
  ul {
    list-style-type: none;
    text-align: left;
    padding-left: 0;
  }
}
.loader {
  margin: auto;
}
</style>
